import React from "react"
import { Link, navigate } from 'gatsby'
import {
	Button,
	Container
} from 'semantic-ui-react'
import Seo from '../components/SEO'
import Layout from '../components/Layout'
import SignInForm from '../components/Forms/signInForm'
import * as styles from './styles/pages.module.css'
import {redirectColor} from '../globalVar'

const Negosyo = require('../../lib/negosyo')

class Login extends React.Component {

	componentDidMount() {
		Negosyo.isLoggedIn()
		.then((data)=>{
			if(data===true) {
				//custom key is for when the navigate's target url has a backButton so the new page inherits the location.key for the site mobileHeader backButton
				navigate(
					`/my-account`,
					{
						replace:true,
						state:{
							customKey:this.props.location.key
						}
					}
				)
			}
		})//doesn't need error since isLoggedIn won't reject promise but will only resolve with true if logged in or false
	}

	render() {
		const {
			location
		} = this.props
		return (
			<Layout location={location}>
				<Seo title={"Sign in"} meta={[{name: `robots`, content: `noindex`}]} />
				<Container className={styles.container}>
					<div className={styles.centerContent}>
						<SignInForm
							handleSubmit={()=>{
								//custom key is for when the navigate's target url has a backButton so the new page inherits the location.key for the site mobileHeader backButton
								let customKey
								if(location.state) {
									if(location.state.customKey) {
										customKey=location.state.customKey
									}else {
										customKey=location.key
									}
								}else {
									customKey=location.key
								}
								navigate(
									"/",
									{
										replace:true,
										state:{
											customKey:customKey
										}
									}
								)
							}}
							shortenForm={true}
						/>
						<div>
							<br/>
							<div className={styles.text}>
								Forgot your password?
								<Button
									as={Link}
									className={styles.mainButton}
									color={redirectColor}
									to="/forgot-password/"
									content="Forgot password"
								/>
							</div>
							<div className={styles.text}>
								(Already have a code)
								<Button
									className={styles.mainButton}
									as={Link}
									color={redirectColor}
									to="/reset-password/"
								>
									Use Password Reset Code
								</Button>
							</div>
							<br/>
							<div className={styles.text}>
								No account yet?
								<Button
									as={Link}
									className={styles.mainButton}
									color={redirectColor}
									to="/register/"
									content="Sign Up"
								/>
							</div>
						</div>
					</div>
				</Container>
			</Layout>
		)
	}
}

export default Login